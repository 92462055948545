﻿/*
 * ========================
 * CONSTANTS
 * ========================
 */
import {
    $CUSTOM_FORM,
    $CUSTOM_FORM_SUBMIT_BUTTON,
    $CUSTOM_FORM_SUBMIT_NAVI_BUTTON,
    CUSTOM_FORM_SUCCESS_MESSAGE,
    $CUSTOM_FORM_ATTACHMENT_1,
    $CUSTOM_FORM_ATTACHMENT_2,
    $CUSTOM_FORM_ATTACHMENT_3,
    $CUSTOM_FORM_ATTACHMENT_4,
    $CUSTOM_FORM_ATTACHMENT_5,
    $HIDDEN_ATTACHMENT_1,
    $HIDDEN_ATTACHMENT_2,
    $HIDDEN_ATTACHMENT_3,
    $HIDDEN_ATTACHMENT_4,
    $HIDDEN_ATTACHMENT_5
} from './constants';

import {
    TOASTER_ERROR_BG_COLOR,
    TOASTER_SUCCESS_BG_COLOR
} from '../../constants';

let isAllowSubmit = 0;

/*
 * ========================
 * CONSTANTS .\END
 * ========================
 */


if ($CUSTOM_FORM_SUBMIT_NAVI_BUTTON.length) {

    $CUSTOM_FORM_SUBMIT_NAVI_BUTTON.click(function () {
        console.log('submit form');

        isAllowSubmit = 0;
        //var fileInput = document.getElementById('files');

        if ($CUSTOM_FORM_ATTACHMENT_1.length) {
            UploadFile($CUSTOM_FORM_ATTACHMENT_1[0], $HIDDEN_ATTACHMENT_1[0]);
        }
        else {
            isAllowSubmit += 1;
        }

        if ($CUSTOM_FORM_ATTACHMENT_2.length) {
            UploadFile($CUSTOM_FORM_ATTACHMENT_2[0], $HIDDEN_ATTACHMENT_2[0]);
        }
        else {
            isAllowSubmit += 1;
        }

        if ($CUSTOM_FORM_ATTACHMENT_3.length) {
            UploadFile($CUSTOM_FORM_ATTACHMENT_3[0], $HIDDEN_ATTACHMENT_3[0]);
        }
        else {
            isAllowSubmit += 1;
        }

        if ($CUSTOM_FORM_ATTACHMENT_4.length) {
            UploadFile($CUSTOM_FORM_ATTACHMENT_4[0], $HIDDEN_ATTACHMENT_4[0]);
        }
        else {
            isAllowSubmit += 1;
        }

        if ($CUSTOM_FORM_ATTACHMENT_5.length) {
            UploadFile($CUSTOM_FORM_ATTACHMENT_5[0], $HIDDEN_ATTACHMENT_5[0]);
        }
        else {
            isAllowSubmit += 1;
        }

        if (isAllowSubmit === 5) {
            $CUSTOM_FORM_SUBMIT_BUTTON.click();
        }

    });

}

function UploadFile(fileInput, fileInputHidden) {

    var formdata = new FormData(); //FormData object

    if (fileInput.files.length > 0) {

        //Iterating through each files selected in fileInput
        for (var i = 0; i < fileInput.files.length; i++) {
            //Appending each file to FormData object
            formdata.append(fileInput.files[i].name, fileInput.files[i]);
        }

        //Creating an XMLHttpRequest and sending
        var xhr = new XMLHttpRequest();
        xhr.open('POST', '/umbraco/surface/Member/Upload');
        xhr.send(formdata);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                $(fileInputHidden).val(xhr.responseText);
                isAllowSubmit += 1;

                if (isAllowSubmit === 5) {
                    $CUSTOM_FORM_SUBMIT_BUTTON.click();
                }
            }

        };
    }
    else {
        isAllowSubmit += 1;
    }
   
}


/*
 * ========================
 * Event handlers for CONTACT
 * US AJAX (Begin, Failure,
 * SUCCESS).
 * ========================
 */
function OnCustomFormBegin() {
    $CUSTOM_FORM_SUBMIT_BUTTON
        .attr('disabled', true)
        .addClass('disabled');
}

// expose it to global scope.
window.OnCustomFormBegin = OnCustomFormBegin;

function OnCustomFormFailure(err) {
    $CUSTOM_FORM_SUBMIT_BUTTON
        .attr('disabled', false)
        .removeClass('disabled');

    Toaster({
        text: err.statusText,
        gravity: 'bottom',
        position: 'right',
        duration: 5000,
        backgroundColor: TOASTER_ERROR_BG_COLOR
    });
}

// expose it to global scope.
window.OnCustomFormFailure = OnCustomFormFailure;

function OnCustomFormSuccess() {

    $CUSTOM_FORM
        .trigger('reset');

    $CUSTOM_FORM_SUBMIT_BUTTON
        .attr('disabled', false)
        .removeClass('disabled');

    Toaster({
        text: CUSTOM_FORM_SUCCESS_MESSAGE,
        gravity: 'bottom',
        position: 'right',
        duration: 5000,
        backgroundColor: TOASTER_SUCCESS_BG_COLOR
    });
}

// expose it to global scope.
window.OnCustomFormSuccess = OnCustomFormSuccess;

/*
 * ========================
 * Event handlers for CONTACT
 * US AJAX (Begin, Failure,
 * SUCCESS).
 * .\END
 * ========================
 */